@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");

$blue: #005fc3;

html,
body {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  /* background-image: url("../public/easycapital_01.svg") ; */
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-display: swap;
}

.ant-popover-message {
  display: flex;
  align-items: center;
  svg {
    color: $blue;
    font-size: 20px;
  }
}

#nprogress {
  .bar {
    background: $blue !important;
  }
  .spinner-icon {
    border-top-color: $blue !important;
    border-left-color: $blue !important;
  }
  .peg {
    box-shadow: 0 0 10px $blue, 0 0 5px $blue !important;
  }
}

.ant-drawer-content-wrapper {
  width: 60% !important;
}
.ant-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding: 0px;
  align-items: center;
  padding-bottom: 20px;
  max-height: 100vh !important;
  svg {
    font-size: 26px;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .top a,
  .ant-btn-link {
    height: 50px;
    border-bottom: 1px solid grey;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: black;
  }
  img {
    width: 80%;
    margin-bottom: 20px;
  }
  .ant-btn-primary {
    font-size: 14px;
    margin-top: 10px;
  }
  .ant-btn-secondary {
    font-size: 14px;
    border: 1px solid lightgrey;
    color: black;
  }
}
.reportBugDrawer {
  .ant-drawer-content-wrapper {
    width: 500px !important;
  }
  .ant-drawer-body {
    overflow: hidden;
  }
}

.linkAdvertModal {
  .ant-modal-body {
    display: flex;
    justify-content: space-around;
  }
}

.ant-btn {
  > * {
    margin: 0 3px;
  }
}

.advertsTablePopoverContent {
  display: flex;
  * {
    margin: 0 1px !important;
  }
}

.closablePopover {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .xMark {
    margin: 5px;
    cursor: pointer;
  }
}

.downloadFolderIcon {
  color: $blue;
  font-size: 50px !important;
  cursor: pointer;
  &:hover {
    font-size: 60px !important;
  }
}
